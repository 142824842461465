<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="小区名称">
            <el-input v-model="searchForm.name" placeholder="请输入 小区名称"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称" prop="name" :rules="[{ required: true, message: '请输入 小区名称' }]">
              <el-input clearable v-model="form.name" placeholder="请输入 小区名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="小区编号" prop="serial" :rules="[{ required: true, message: '请输入 小区编号' }]">
              <el-input clearable v-model="form.serial" placeholder="请输入 小区编号" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属区域" prop="propsId" :rules="[{ required: true, message: '请选择 所属区域' }]">
              <!--              <el-tree :data="regiontree" :props="treeSelectProps" show-checkbox accordion node-key="code" ref="tree" check-strictly @check-change="checkChange" check-on-click-node />-->
              <selectTree :treeData="regiontree" @selectTreeChange="selectTreeChange" :treeProps="treeProps" :propsId="searchpropsId" ref="selectTree"></selectTree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经纬度" prop="latitude" :rules="[{ required: true, message: '经纬度格式错误（例）xxxxx,xxxxx' }]">
              <Getnpoint ref="point" @SET_POINT="setpoint" @INPUT_POINT="inputpoint"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="小区描述" prop="detail">
              <el-input clearable v-model="form.detail" placeholder="请输入 小区描述" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
        append-to-body
        v-model="editFormVisible"
        title="编辑"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称" prop="name" :rules="[{ required: true, message: '请输入 小区名称' }]">
              <el-input clearable v-model="form.name" placeholder="请输入 小区名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经纬度" prop="latitude" :rules="[{ required: true, message: '经纬度 不能为空' }]">
              <Getnpoint ref="editpoint" @SET_POINT="setpoint" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="小区描述" prop="detail">
              <el-input clearable v-model="form.detail" placeholder="请输入 小区描述" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="editSave">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { dataList, addData, editData, delData } from "@/api/popularization/community";
import Searchbar from '@/components/Searchbar';
import selectTree from "@/components/selectTree";
import Getnpoint from '@/components/Getnpoint'
import {getRegionById} from "@/api/system/region";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components:{
    Searchbar,
    selectTree,
    Getnpoint
  },
  data(){
    return{
      treeProps:{
        children: "children",
        label: "name",
        key: "code"
      },
      searchpropsId: 0,
      editFormVisible:false,
      treeSelectProps: {
        label: "name",
        value: "code",
        key: "code",
        children: "children"
      },
      region:{},
      regiontree:[],
      title:"",
      dialogFormVisible: false,
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "name",
          label: "小区名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "serial",
          label: "小区编号",
          render: (row) => <div>{row.serial}</div>,
        },
        {
          prop: "detail",
          label: "小区描述",
          render: (row) => <div>{row.detail}</div>,
        },
        {
          prop: "code",
          label: "小区编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "region_name",
          label: "区域名称",
          render: (row) => <div>{row.region_name}</div>,
        },
        {
          prop: "region_code",
          label: "所属编码",
          render: (row) => <div>{row.region_code}</div>,
        },
        {
          prop: "",
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>this.dataEdit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>this.del(row)}>
                  删除
                </el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  watch:{

  },
  created() {
    this.onLoad();
    getRegionById().then(res => {
      if (res.data.success) {
        this.regiontree = res.data.data;
      }
    });
  },
  methods:{
    inputpoint(val){
      let point = val.split(',');
      this.form.longitude = point[0];
      this.form.latitude = point[1];
    },

    setpoint(val){
      let point = val.split(',');
      this.form.longitude = point[0];
      this.form.latitude = point[1];
    },

    selectTreeChange(value, label) {
      this.region = {
        code: value,
        name: label
      }
      this.form.propsId = value;
    },
    handleClose(done){
      done();
      this.$nextTick(()=>{
        this.form = {};
        this.$refs['dataform'].resetFields();
      })
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },

    del(row){
      ElMessageBox.confirm(
          '此操作将永久删除该数据, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delData({id:row.id}).then(res=>{
          ElMessage({message: res.data.code.message, type: 'success'});
          this.page.current = 1;
          this.page.limit = 10;
          this.searchForm = {};
          this.onLoad();
        })
      }).catch(() => {})
    },
    editSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let {id, name,longitude,latitude,detail} = this.form;
          editData({
            id, name,longitude,latitude,detail
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.editFormVisible = false;
              this.$nextTick(()=>{
                this.form = {};
                this.$refs['dataform'].resetFields();
              })
              this.onLoad();
            }
          })
        } else {
          return false;
        }
      });
    },

    dataEdit(row){
      this.form = JSON.parse(JSON.stringify(row));
      this.editFormVisible = true;
      this.$nextTick(()=>{
        this.$refs['editpoint'].points = `${this.form.longitude},${this.form.latitude}`;
      })
    },
    onSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let {name,serial,longitude,latitude,detail} = this.form;
          addData({
            name, serial, region_code:this.region.code, region_name:this.region.name, longitude, latitude, detail
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              this.dialogFormVisible = false;
              this.page.current = 1;
              this.page.limit = 10;
              this.searchForm = {};
              this.$nextTick(()=>{
                this.form = {};
                this.$refs['dataform'].resetFields();
              })
              this.onLoad();
            }
          })
        } else {
          return false;
        }
      });
    },
    // checkChange(data) {
    //   this.region = data;
    //   this.form.propsId = data.code;
    //   this.$refs.tree.setCheckedKeys([data.code]);
    // },
    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tree-node__content{
  height: 45px;
}
</style>