import axios from "@/api/axios"; // 引用axios

export const addData = (params) => {
    return axios({
        url: "/api/community/addData",
        method: "post",
        params: params,
    });
};

export const dataList = (params) => {
    return axios({
        url: "/api/community/listData",
        method: "post",
        params: params,
    });
};

export const editData = (params) => {
    return axios({
        url: "/api/community/editData",
        method: "post",
        params: params,
    });
};

export const delData = (params) => {
    return axios({
        url: "/api/community/del",
        method: "post",
        params: params,
    });
};
